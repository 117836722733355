var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vehicle-ctrl",
      staticStyle: { height: "100%" },
      attrs: { id: "vehicle-ctrl" },
    },
    [
      _c(
        "vxe-modal",
        {
          ref: "vxeModal",
          class: [
            !_vm.isCanSendWebsocketCmd ? "remote-control-warn" : "",
            "ctrl-bg",
          ],
          attrs: {
            className: "vehicle-ctrl-screen",
            width: _vm.modalWidth,
            height: "100%",
            title: _vm.modalTitle,
            "show-zoom": "",
            resize: "",
            "lock-view": false,
            mask: false,
            "esc-closable": true,
            position: { top: 0, left: 0 },
            dblclickZoom: false,
            transfer: "",
          },
          on: { close: _vm.handleCloaseCtrl, hide: _vm.handleCloaseCtrl },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "vxe-modal--header-title" }, [
                    _vm._v(_vm._s(_vm.modalTitle)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vxe-modal--header-right" },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "10px" },
                          on: { click: _vm.toRtcScreen },
                        },
                        [_vm._v(" 切换遥控 ")]
                      ),
                      _c("a-icon", {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["tenant:iot:remotecontrol:delay"],
                            expression: "['tenant:iot:remotecontrol:delay']",
                          },
                        ],
                        staticStyle: { "margin-right": "5px" },
                        attrs: { type: "line-chart" },
                        on: { click: _vm.handleOpenChart },
                      }),
                      _vm.initIsRtc
                        ? _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["remote_vedio_type_switch"],
                                  expression: "['remote_vedio_type_switch']",
                                },
                              ],
                              on: { click: _vm.changeAdminVideoType },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.signAdminVideoType === "rtc"
                                      ? "切换rtmp"
                                      : "切换rtc"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isTianzun
                        ? _c("i", {
                            staticClass: "vxe-modal--right-btn",
                            class: _vm.voiceOn
                              ? "vxe-icon-voice-fill"
                              : "vxe-icon-voice",
                            attrs: {
                              title: _vm.voiceOn ? "语音开启中" : "语音关闭中",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.connectVoice(
                                  _vm.voiceOn ? "off" : "on"
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _c("i", {
                        staticClass: "vxe-modal--zoom-btn trigger--btn",
                        class: _vm.zoomIn
                          ? "vxe-icon-maximize"
                          : "vxe-icon-square",
                        attrs: { title: "vxe.modal.zoomIn" },
                        on: { click: _vm.vxeZoom },
                      }),
                      _c("i", {
                        staticClass:
                          "vxe-modal--close-btn trigger--btn vxe-icon-close",
                        attrs: { title: "vxe.modal.close" },
                        on: { click: _vm.vxeClick },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.ctrlVisible,
            callback: function ($$v) {
              _vm.ctrlVisible = $$v
            },
            expression: "ctrlVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "ctrl-container", attrs: { id: "ctrlContainer" } },
            [
              _c("div", { staticClass: "ctrl-left" }, [
                _c("div", { staticClass: "ctrl-info" }, [
                  _c("div", { staticClass: "speed" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../../../../assets/images/control/bg-speed.png"),
                        height: "100%",
                      },
                    }),
                    _c("div", { staticClass: "speed-over" }, [
                      _c("span", { staticClass: "speed-text speed-media" }, [
                        _vm._v(_vm._s(_vm.runningSpeed)),
                      ]),
                      _c(
                        "span",
                        { staticClass: "speed-label speed-label-media" },
                        [_vm._v("速度(KM/H)")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "rotate" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../../../../assets/images/control/bg-direction.png"),
                        height: "100%",
                      },
                    }),
                    _c("div", { staticClass: "rotate-over" }, [
                      _c("img", {
                        staticClass: "rotate-arrow",
                        style: {
                          transform: "rotate(" + _vm.locationHeading + "deg)",
                        },
                        attrs: {
                          src: require("../../../../../../assets/images/control/ico-arrow.png"),
                        },
                      }),
                      _c("span", { staticClass: "rotate-text rotate-media" }, [
                        _vm._v(_vm._s(_vm.locationHeading) + "°"),
                      ]),
                      _c(
                        "span",
                        { staticClass: "rotate-label rotate-label-media" },
                        [_vm._v("方向" + _vm._s(_vm.locationHeadingLRStr))]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "info-content" }, [
                    _c(
                      "div",
                      { staticClass: "soc", style: _vm.leftLabelStyle },
                      [
                        _c("span", { staticClass: "info-item" }, [
                          _vm._v("电量：" + _vm._s(_vm.currentSoc) + "%"),
                        ]),
                        _c("img", {
                          style: {
                            width: _vm.currentSoc + "%",
                            height: "14px",
                            marginTop: "4px",
                          },
                          attrs: {
                            src: require("../../../../../../assets/images/control/bg-remainingPower.png"),
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "brake", style: _vm.leftLabelStyle },
                      [
                        _c("span", { staticClass: "info-item" }, [
                          _vm._v(
                            "刹车情况：" +
                              _vm._s(_vm.cmd.brake ? "刹车中" : "未刹车")
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "state", style: _vm.leftLabelStyle },
                      [
                        _c("span", { staticClass: "info-item" }, [
                          _vm._v("作业状态： " + _vm._s(_vm.workStatus)),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "ctrl-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "main-camera",
                    class: [
                      _vm.mainCamera.url || _vm.mainCamera.info
                        ? ""
                        : "no-video-big",
                    ],
                  },
                  [
                    _vm.videoType === "brtc"
                      ? _c("div", { attrs: { id: "therevideo2" } })
                      : _vm._e(),
                    (_vm.mainCamera.url || _vm.mainCamera.info) &&
                    _vm.reLoadRevo
                      ? _c("revo-video-player", {
                          attrs: {
                            "is-live": true,
                            vehicleId: _vm.vehicleId,
                            rtcInfo: _vm.rtcInfo,
                            vehicleCameraDeviceInfo: _vm.mainCamera.info,
                            direction: _vm.mainCamera.direction,
                            url: _vm.mainCamera.url,
                            videoType: _vm.isAdmin
                              ? _vm.signAdminVideoType
                              : _vm.videoType,
                          },
                          on: {
                            handleReady: _vm.handleReady,
                            handleError: _vm.handleError,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "ctrl-btn" }, [
                  _c("div", { staticClass: "btn-list" }, [
                    _c("div", { staticClass: "row-1" }, [
                      _c("div", { staticClass: "col-1" }, [
                        _c("div", { staticClass: "btn-s" }, [
                          _c("img", {
                            staticClass: "ctrl-btn-left",
                            attrs: { src: _vm.getImgSrc("btn-a", _vm.leftBtn) },
                            on: {
                              mousedown: _vm.turnLeftClickDown,
                              mouseup: _vm.turnLeftClickUp,
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "btn-v" }, [
                          _c("img", {
                            staticClass: "ctrl-w",
                            attrs: {
                              src: _vm.getImgSrc("btn-w", _vm.upGearBtn),
                            },
                            on: { click: _vm.forward },
                          }),
                          _c("img", {
                            staticClass: "ctrl-s",
                            attrs: {
                              src: _vm.getImgSrc("btn-s", _vm.downGearBtn),
                            },
                            on: { click: _vm.reversing },
                          }),
                          _c("img", {
                            staticClass: "ctrl-n",
                            attrs: {
                              src: _vm.getImgSrc(
                                "btn-n",
                                _vm.neutralPositionBtn
                              ),
                            },
                            on: { click: _vm.neutral },
                          }),
                          _c("img", {
                            staticClass: "ctrl-p",
                            attrs: {
                              src: _vm.getImgSrc("btn-p", _vm.parkingBtn),
                            },
                            on: { click: _vm.parking },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "btn-v" }),
                        _c("div", { staticClass: "col-2-right" }, [
                          _c("div", { staticClass: "ctrl-mode" }, [
                            _c("div", [
                              _c("img", {
                                staticClass: "ctrl-n",
                                attrs: { src: _vm.manualModeImgSrc },
                                on: { click: _vm.unautomode },
                              }),
                              _c("img", {
                                staticClass: "ctrl-n",
                                attrs: { src: _vm.autoModeImgSrc },
                                on: { click: _vm.automode },
                              }),
                            ]),
                            _c(
                              "span",
                              {
                                class: [
                                  !_vm.isLoginSuccess
                                    ? "ctrl-mode-text-grey"
                                    : "ctrl-mode-text",
                                ],
                              },
                              [_vm._v("驾驶模式")]
                            ),
                          ]),
                          _c("div", { staticClass: "ctrl-jk" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.getImgSrc("btn-j", _vm.throttleBtn),
                              },
                              on: {
                                mousedown: _vm.guanDown,
                                mouseup: _vm.guanUp,
                              },
                            }),
                            _c("img", {
                              attrs: {
                                src: _vm.getImgSrc("btn-k", _vm.brakeBtn),
                              },
                              on: {
                                mousedown: _vm.brakeDown,
                                mouseup: _vm.brakeUp,
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "btn-s" }, [
                          _c("img", {
                            staticClass: "ctrl-btn-right",
                            attrs: {
                              src: _vm.getImgSrc("btn-d", _vm.rightBtn),
                            },
                            on: {
                              mousedown: _vm.turnRightClickDown,
                              mouseup: _vm.turnRightClickUp,
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("img", {
                      staticClass: "ctrl-estop",
                      attrs: {
                        src: _vm.getImgSrc("btn-space", _vm.emergencyStop),
                      },
                      on: { click: _vm.estop },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "ctrl-right" }, [
                _c(
                  "div",
                  { staticClass: "ctrl-map" },
                  [
                    _c("map-page", {
                      ref: "mapRef",
                      attrs: { params: _vm.mapPageParams },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _c("MonitorVehicleCtrlDelayChart", { ref: "chartRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }