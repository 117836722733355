import request from '@/utils/request'

const carApi = {
    baseUrl: '/iot/vehicle/'
}

export function carMove (parameter) {
    return request({
      url: carApi.baseUrl + parameter.vehicleId + '/command/motivation',
      method: 'post',
      data: { movation_code: parameter.movation_code, step_distance: parameter.step_distance }
    })
}

export function carMode (parameter) {
    return request({
      url: carApi.baseUrl + parameter.vehicleId + '/command/driveMode',
      method: 'post',
      data: { drive_mode: parameter.drive_mode }
    })
}

export function setVideo (parameter) {
  return request({
    url: carApi.baseUrl + parameter.vehicleId + '/camera',
    method: 'patch',
    data: parameter.data
  })
}

// 车辆切换驾驶模式
export function setAutomode (parameter) {
  return request({
    url: '/vehicle/cmd/' + parameter.vehicleId + '/command/automode',
    method: 'post',
    data: parameter.data
  })
}

// 登陆车辆遥控
export function login (parameter) {
  return request({
    url: '/vehicle/cmd/' + parameter.vehicleId + '/command/login',
    method: 'post',
    data: parameter.data
  })
}

// 登出车辆遥控
export function logout (parameter) {
  return request({
    url: '/vehicle/cmd/' + parameter.vehicleId + '/command/logout',
    method: 'post',
    data: parameter.data
  })
}
